import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spe4lls-telekinesis-to-zone-of-truth"></a><h2>Spells</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Telekinesis - Zone of Truth</span>
    </h2>
    <h6><a id="telekinesis">Telekinesis</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Target or Targets</b>:
See text</span> <span className="stat-block"><b>Duration</b>:
Concentration (up to 1
round/ level) or instantaneous; see text</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(object) or None; see text</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes (object);
see text</span>
    <p>You move objects or creatures by concentrating on them.
Depending on the version selected, the spell can provide a gentle,
sustained force, perform a variety of combat maneuvers, or exert a
single short, violent thrust.</p>
    <p><i>Sustained Force</i>: A sustained force moves an object
weighing no more than 25 pounds per caster level (maximum 375 pounds at
15th level) up to 20 feet per round. A creature can negate the effect
on an object it possesses with a successful Will save or with spell
resistance.</p>
    <p>This version of the spell can last 1 round per caster level,
but it ends if you cease concentration. The weight can be moved
vertically, horizontally, or in both directions. An object cannot be
moved beyond your range. The spell ends if the object is forced beyond
the range. If you cease concentration for any reason, the object falls
or stops.</p>
    <p>An object can be telekinetically manipulated as if with one
hand. For example, a lever or rope can be pulled, a key can be turned,
an object rotated, and so on, if the force required is within the
weight limitation. You might even be able to untie simple knots, though
delicate activities such as these require Intelligence checks.</p>
    <p><i>Combat Maneuver</i>: Alternatively, once per round, you can
use telekinesis to perform a <a href="specialAttacks.html#bull-rush" style={{
        "color": "rgb(87, 158, 182)"
      }}>bull rush</a>, <a href="specialAttacks.html#disarm" style={{
        "color": "rgb(87, 158, 182)"
      }}>disarm</a>,
      <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> (including
pin), or <a href="specialAttacks.html#trip" style={{
        "color": "rgb(87, 158, 182)"
      }}>trip</a>. Resolve these attempts as
normal, except that they don&rsquo;t
provoke attacks of opportunity, you use your caster level in place of
your base attack bonus (for disarm and grapple), you use your
Intelligence modifier (if a wizard) or Charisma modifier (if a
sorcerer) in place of your Strength or Dexterity modifier, and a failed
attempt doesn&rsquo;t allow a reactive attempt by the target (such as
for
disarm or trip). No save is allowed against these attempts, but spell
resistance applies normally. This version of the spell can last 1 round
per caster level, but it ends if you cease concentration.</p>
    <p><i>Violent Thrust</i>: Alternatively, the spell energy can be
spent in a single round. You can hurl one object or creature per caster
level (maximum 15) that are within range and all within 10 feet of each
other toward any target within 10 feet per level of all the objects.
You can hurl up to a total weight of 25 pounds per caster level
(maximum 375 pounds at 15th level).</p>
    <p>You must succeed on attack rolls (one per creature or object
thrown) to hit the target with the items, using your base attack bonus
+ your Intelligence modifier (if a wizard) or Charisma modifier (if a
sorcerer). Weapons cause standard damage (with no Strength bonus; note
that arrows or bolts deal damage as daggers of their size when used in
this manner). Other objects cause damage ranging from 1 point per 25
pounds (for less dangerous objects) to 1d6 points of damage per 25
pounds (for hard, dense objects).</p>
    <p>Creatures who fall within the weight capacity of the spell can
be hurled, but they are allowed Will saves (and spell resistance) to
negate the effect, as are those whose held possessions are targeted by
the spell. If a telekinesed creature is hurled against a solid surface,
it takes damage as if it had fallen 10 feet (1d6 points).</p>
    <h6><a id="telekinetic-sphere">Telekinetic Sphere</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>:
1-ft.-diameter/level
sphere, centered around creatures or objects</span> <span className="stat-block"><b>Duration</b>: 1 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>: Reflex negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#resilient-sphere">resilient sphere</a>,with the addition
that the creatures or objects inside the globe are nearly weightless.
Anything contained within an telekinetic sphere weighs only
one-sixteenth of its normal weight. You can telekinetically lift
anything in the sphere that normally weighs 5,000 pounds or less. The
telekinetic control extends from you out to medium range (100 feet + 10
feet per caster level) after the sphere has succeeded in encapsulating
its contents.</p>
    <p>You can move objects or creatures in the sphere that weigh a
total of 5,000 pounds or less by concentrating on the sphere. You can
begin moving a sphere in the round after casting the spell. If you
concentrate on doing so (a standard action), you can move the sphere as
much as 30 feet in a round. If you cease concentrating, the sphere does
not move in that round (if on a level surface) or descends at its
falling rate (if aloft) until it reaches a level surface, or the
spell&rsquo;s duration expires, or you begin concentrating again. If
you
cease concentrating (voluntarily or due to failing a <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
check), you can resume concentrating on your next turn or any later
turn during the spell&rsquo;s duration.</p>
    <p>The sphere falls at a rate of only 60 feet per round, which is
not fast enough to cause damage to the contents of the sphere.</p>
    <p>You can move the sphere telekinetically even if you are in it.</p>
    <p><i>Material Component</i>: A hemispherical piece of clear
crystal, a matching hemispherical piece of gum arabic, and a pair of
small bar magnets.</p>
    <h6><a id="telepathic-bond">Telepathic Bond</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: You plus one
willing
creature per three levels, no two of which can be more than 30 ft. apart</span>
    <span className="stat-block"><b>Duration</b>: 10 min./level (D)</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You forge a telepathic bond among yourself and a number of
willing creatures, each of which must have an Intelligence score of 3
or higher. Each creature included in the link is linked to all the
others. The creatures can communicate telepathically through the bond
regardless of language. No special power or influence is established as
a result of the bond. Once the bond is formed, it works over any
distance (although not from one plane to another).</p>
    <p>If desired, you may leave yourself out of the telepathic bond
forged. This decision must be made at the time of casting.</p>
    <p>Telepathic bond can be made permanent with a permanency spell,
though it only bonds two creatures per casting of permanency.</p>
    <p><i>Material Component</i>: Pieces of eggshell from two
different kinds of creatures.</p>
    <h6><a id="teleport">Teleport</a></h6>
    <p className="initial"><i>Conjuration (Teleportation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5, Travel 5</span>
    <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal and touch</span> <span className="stat-block"><b>Target</b>: You and touched objects
or other touched willing creatures</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
None and Will
negates (object)</span> <span className="stat-block"><b>Spell Resistance</b>:
No and Yes
(object)</span>
    <p>This spell instantly transports you to a designated
destination, which may be as distant as 100 miles per caster level.
Interplanar travel is not possible. You can bring along objects as long
as their weight doesn&rsquo;t exceed your maximum load. You may also
bring
one additional willing Medium or smaller creature (carrying gear or
objects up to its maximum load) or its equivalent (see below) per three
caster levels. A Large creature counts as two Medium creatures, a Huge
creature counts as two Large creatures, and so forth. All creatures to
be transported must be in contact with one another, and at least one of
those creatures must be in contact with you. As with all spells where
the range is personal and the target is you, you need not make a saving
throw, nor is spell resistance applicable to you. Only objects held or
in use (attended) by another person receive saving throws and spell
resistance.</p>
    <p>You must have some clear idea of the location and layout of
the destination. The clearer your mental image, the more likely the
teleportation works. Areas of strong physical or magical energy may
make teleportation more hazardous or even impossible.</p>
    <p>To see how well the teleportation works, roll d% and consult
the Teleport table. Refer to the following information for definitions
of the terms on the table.</p>
    <p><i>Familiarity</i>: &ldquo;Very familiar&rdquo; is a place
where you have
been very often and where you feel at home. &ldquo;Studied
carefully&rdquo; is a
place you know well, either because you can currently see it,
you&rsquo;ve
been there often, or you have used other means (such as scrying) to
study the place for at least one hour. &ldquo;Seen casually&rdquo; is a
place that
you have seen more than once but with which you are not very familiar.
&ldquo;Viewed once&rdquo; is a place that you have seen once, possibly
using magic. </p>
    <p>&ldquo;False destination&rdquo; is a place that does not truly
exist or if
you are teleporting to an otherwise familiar location that no longer
exists as such or has been so completely altered as to no longer be
familiar to you. When traveling to a false destination, roll 1d20+80 to
obtain results on the table, rather than rolling d%, since there is no
real destination for you to hope to arrive at or even be off target
from.</p>
    <p><i>On Target</i>: You appear where you want to be.</p>
    <p><i>Off Target</i>: You appear safely a random distance away
from the destination in a random direction. Distance off target is
1d10x1d10% of the distance that was to be traveled. The direction off
target is determined randomly</p>
    <p><i>Similar Area</i>: You wind up in an area that&rsquo;s
visually or
thematically similar to the target area.</p>
    <p>Generally, you appear in the closest similar place within
range. If no such area exists within the spell&rsquo;s range, the spell
simply fails instead.</p>
    <p><i>Mishap</i>: You and anyone else teleporting with you have
gotten &ldquo;scrambled.&rdquo; You each take 1d10 points of damage,
and you reroll
on the chart to see where you wind up. For these rerolls, roll 1d20+80.
Each time &ldquo;Mishap&rdquo; comes up, the characters take more
damage and must
reroll.</p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "60%"
    }}>
      <tbody>
        <tr>
          <th>Familiarity</th>
          <th>On Target</th>
          <th>Off Target</th>
          <th>Similar Area</th>
          <th>Mishap</th>
        </tr>
        <tr className="odd-row">
          <td>Very familiar</td>
          <td>01&ndash;97</td>
          <td>98&ndash;99</td>
          <td>100</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>Studied carefully</td>
          <td>01&ndash;94</td>
          <td>95&ndash;97</td>
          <td>98&ndash;99</td>
          <td>100</td>
        </tr>
        <tr className="odd-row">
          <td>Seen casually</td>
          <td>01&ndash;88</td>
          <td>89&ndash;94</td>
          <td>95&ndash;98</td>
          <td>99&ndash;100</td>
        </tr>
        <tr>
          <td>Viewed once</td>
          <td>01&ndash;76</td>
          <td>77&ndash;88</td>
          <td>89&ndash;96</td>
          <td>97&ndash;100</td>
        </tr>
        <tr className="odd-row">
          <td>False destination (1d20+80)</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>81&ndash;92</td>
          <td>93&ndash;100</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="teleport-object">Teleport Object</a></h6>
    <p className="initial"><i>Conjuration (Teleportation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
One touched object of up
to 50 lb./level and 3 cu. ft./level</span> <span className="stat-block"><b>Saving
Throw</b>: Will negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#teleport">teleport</a>, except that it teleports
an object, not you. Creatures and magical forces cannot be teleported.</p>
    <p>If desired, the target object can be sent to a distant
location on the Ethereal Plane. In this case, the point from which the
object was teleported remains faintly magical until the item is
retrieved. A successful targeted dispel magic spell cast on that point
brings the vanished item back from the Ethereal Plane.</p>
    <h6><a id="greater-teleport">Teleport, Greater</a></h6>
    <p className="initial"><i>Conjuration (Teleportation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7, Travel 7</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#teleport">teleport</a>, except that there is no
range limit and there is no chance you arrive off target. In addition,
you need not have seen the destination, but in that case you must have
at least a reliable description of the place to which you are
teleporting. If you attempt to teleport with insufficient information
(or with misleading information), you disappear and simply reappear in
your original location. Interplanar travel is not possible.</p>
    <h6><a id="teleportation-circle">Teleportation Circle</a></h6>
    <p className="initial"><i>Conjuration (Teleportation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 9</span> <span className="stat-block"><b>Components</b>: V, M</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: 0 ft.</span> <span className="stat-block"><b>Effect</b>:
5-ft.-radius circle that
teleports those who activate it</span> <span className="stat-block"><b>Duration</b>:
10 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You create a circle on the floor or other horizontal surface
that teleports, as greater teleport, any creature who stands on it to a
designated spot. Once you designate the destination for the circle, you
can&rsquo;t change it. The spell fails if you attempt to set the circle
to
teleport creatures into a solid object, to a place with which you are
not familiar and have no clear description, or to another plane.</p>
    <p>The circle itself is subtle and nearly impossible to notice.
If you intend to keep creatures from activating it accidentally, you
need to mark the circle in some way.</p>
    <p>Teleportation circle can be made permanent with a permanency
spell. A permanent teleportation circle that is disabled becomes
inactive for 10 minutes, then can be triggered again as normal.</p>
    <p><i>Note</i>: Magic traps such as teleportation circle are hard
to detect and disable. A rogue (only) can use the <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
skill to find
the circle and <a href="skillsAll.html#disable-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disable Device</a> to thwart it. The
DC in each case is 25 +
spell level, or 34 in the case of teleportation circle.</p>
    <p><i>Material Component</i>: Amber dust to cover the area of the
circle (cost 1,000 gp).</p>
    <h6><a id="temporal-stasis">Temporal Stasis</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>:
Permanent</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>You must succeed on a melee touch attack. You place the
subject into a state of suspended animation. For the creature, time
ceases to flow and its condition becomes fixed. The creature does not
grow older. Its body functions virtually cease, and no force or effect
can harm it. This state persists until the magic is removed (such as by
a successful dispel magic spell or a freedom spell).</p>
    <p><i>Material Component</i>: A powder composed of diamond,
emerald, ruby, and sapphire dust with a total value of at least 5,000
gp.</p>
    <h6><a id="time-stop">Time Stop</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 9, Trickery 9</span>
    <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1d4+1 rounds (apparent
time); see text</span>
    <p>This spell seems to make time cease to flow for everyone but
you. In fact, you speed up so greatly that all other creatures seem
frozen, though they are actually still moving at their normal speeds.
You are free to act for 1d4+1 rounds of apparent time. Normal and
magical fire, cold, gas, and the like can still harm you. While the
time stop is in effect, other creatures are invulnerable to your
attacks and spells; you cannot target such creatures with any attack or
spell. A spell that affects an area and has a duration longer than the
remaining duration of the time stop have their normal effects on other
creatures once the time stop ends. Most spellcasters use the additional
time to improve their defenses, summon allies, or flee from combat.</p>
    <p>You cannot move or harm items held, carried, or worn by a
creature stuck in normal time, but you can affect any item that is not
in another creature&rsquo;s possession.</p>
    <p>You are undetectable while time stop lasts. You cannot enter
an area protected by an antimagic field while under the effect of time
stop.</p>
    <h6><a id="tiny-hut">Tiny Hut</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 20 ft.</span> <span className="stat-block"><b>Effect</b>: 20-ft.-radius sphere
centered on your location</span> <span className="stat-block"><b>Duration</b>:
2 hours/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You create an unmoving, opaque sphere of force of any color
you desire around yourself. Half the sphere projects above the ground,
and the lower hemisphere passes through the ground. As many as nine
other Medium creatures can fit into the field with you; they can freely
pass into and out of the hut without harming it. However, if you remove
yourself from the hut, the spell ends.</p>
    <p>The temperature inside the hut is 70&deg; F if the exterior
temperature is between 0&deg; and 100&deg; F. An exterior temperature
below 0&deg; or above 100&deg; lowers or raises the interior
temperature on a 1-degree-for-1 basis. The hut also provides protection
against the elements, such as rain, dust, and sandstorms. The hut
withstands any wind of less than hurricane force, but a hurricane (75+
mph wind speed) or greater force destroys it.</p>
    <p>The interior of the hut is a hemisphere. You can illuminate it
dimly upon command or extinguish the light as desired. Although the
force field is opaque from the outside, it is transparent from within.
Missiles, weapons, and most spell effects can pass through the hut
without affecting it, although the occupants cannot be seen from
outside the hut (they have total concealment).</p>
    <p><i>Material Component</i>: A small crystal bead that shatters
when the spell duration expires or the hut is dispelled.</p>
    <h6><a id="tongues">Tongues</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Clr 4, Sor/Wiz 3</span>
    <span className="stat-block"><b>Components</b>: V, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 10
min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell grants the creature touched the ability to speak
and understand the language of any intelligent creature, whether it is
a racial tongue or a regional dialect. The subject can speak only one
language at a time, although it may be able to understand several
languages. Tongues does not enable the subject to speak with creatures
who don&rsquo;t speak. The subject can make itself understood as far as
its
voice carries. This spell does not predispose any creature addressed
toward the subject in any way.</p>
    <p>Tongues can be made permanent with a permanency spell.</p>
    <p><i>Arcane Material Component</i>: A small clay model of a
ziggurat, which shatters when the verbal component is pronounced.</p>
    <h6><a id="touch-of-fatigue">Touch of Fatigue</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 1
round/level</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>You channel negative energy through your touch, fatiguing the
target. You must succeed on a touch attack to strike a target.</p>
    <p>The subject is immediately <a href="abilitiesAndConditions.html#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigued</a> for the spell&rsquo;s
duration.</p>
    <p>This spell has no effect on a creature that is already
fatigued. Unlike with normal fatigue, the effect ends as soon as the
spell&rsquo;s duration expires.</p>
    <p><i>Material Component</i>: A drop of sweat.</p>
    <h6><a id="touch-of-idiocy">Touch of Idiocy</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Living creature touched</span> <span className="stat-block"><b>Duration</b>:
10 min./level</span> <span className="stat-block"><b>Saving Throw</b>: No</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>With a touch, you reduce the target&rsquo;s mental faculties.
Your
successful melee touch attack applies a 1d6 penalty to the
target&rsquo;s
Intelligence, Wisdom, and Charisma scores. This penalty can&rsquo;t
reduce
any of these scores below 1.</p>
    <p>This spell&rsquo;s effect may make it impossible for the
target to
cast some or all of its spells, if the requisite ability score drops
below the minimum required to cast spells of that level.</p>
    <h6><a id="transformation">Transformation</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 round/level</span>
    <p>You become a virtual fighting machine&mdash; stronger,
tougher,
faster, and more skilled in combat. Your mind-set changes so that you
relish combat and you can&rsquo;t cast spells, even from magic items.</p>
    <p>You gain a +4 enhancement bonus to Strength, Dexterity, and
Constitution, a +4 natural armor bonus to AC, a +5 competence bonus on
Fortitude saves, and proficiency with all simple and martial weapons.
Your base attack bonus equals your character level (which may give you
multiple attacks).</p>
    <p>You lose your spellcasting ability, including your ability to
use spell activation or spell completion magic items, just as if the
spells were no longer on your class list.</p>
    <p><i>Material Component</i>: A potion of bull&rsquo;s strength,
which
you drink (and whose effects are subsumed by the spell effects).</p>
    <h6><a id="transmute-metal-to-wood">Transmute Metal to Wood</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 7</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: All metal
objects within a
40-ft.-radius burst</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes (object;
see text)</span>
    <p>This spell enables you to change all metal objects within its
area to wood. Weapons, armor, and other metal objects carried by
creatures are affected as well. A magic object made of metal
effectively has spell resistance equal to 20 + its caster level against
this spell. Artifacts cannot be transmuted. Weapons converted from
metal to wood take a &ndash;2 penalty on attack and damage rolls. The
armor
bonus of any armor converted from metal to wood is reduced by 2.
Weapons changed by this spell splinter and break on any natural attack
roll of 1 or 2, and armor changed by this spell loses an additional
point of armor bonus every time it is struck with a natural attack roll
of 19 or 20.</p>
    <p>Only limited wish, miracle, wish, or similar magic can restore
a transmuted object to its metallic state.</p>
    <h6><a id="transmute-mud-to-rock">Transmute Mud to Rock</a></h6>
    <p className="initial"><i>Transmutation [Earth]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 5, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: Up to two
10-ft.
cubes/level (S)</span> <span className="stat-block"><b>Duration</b>:
Permanent</span> <span className="stat-block"><b>Saving Throw</b>: See text</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell transforms normal mud or quicksand of any depth
into soft stone (sandstone or a similar mineral) permanently.</p>
    <p>Any creature in the mud is allowed a Reflex save to escape
before the area is hardened to stone.</p>
    <p>Transmute mud to rock counters and dispels transmute rock to
mud.</p>
    <p><i>Arcane Material Component</i>: Sand, lime, and water.</p>
    <h6><a id="transmute-rock-to-mud">Transmute Rock to Mud</a></h6>
    <p className="initial"><i>Transmutation [Earth]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 5, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: Up to two
10-ft.
cubes/level (S)</span> <span className="stat-block"><b>Duration</b>:
Permanent; see text</span> <span className="stat-block"><b>Saving Throw</b>:
See text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell turns natural, uncut or unworked rock of any sort
into an equal volume of mud. Magical stone is not affected by the
spell. The depth of the mud created cannot exceed 10 feet. A creature
unable to levitate, fly, or otherwise free itself from the mud sinks
until hip- or chest-deep, reducing its speed to 5 feet and causing a
&ndash;2
penalty on attack rolls and AC. Brush thrown atop the mud can support
creatures able to climb on top of it. Creatures large enough to walk on
the bottom can wade through the area at a speed of 5 feet.</p>
    <p>If transmute rock to mud is cast upon the ceiling of a cavern
or tunnel, the mud falls to the floor and spreads out in a pool at a
depth of 5 feet. The falling mud and the ensuing cave-in deal 8d6
points of bludgeoning damage to anyone caught directly beneath the
area, or half damage to those who succeed on Reflex saves.</p>
    <p>Castles and large stone buildings are generally immune to the
effect of the spell, since transmute rock to mud can&rsquo;t affect
worked
stone and doesn&rsquo;t reach deep enough to undermine such
buildings&rsquo;
foundations. However, small buildings or structures often rest upon
foundations shallow enough to be damaged or even partially toppled by
this spell.</p>
    <p>The mud remains until a successful dispel magic or transmute
mud to rock spell restores its substance&mdash;but not necessarily its
form.
Evaporation turns the mud to normal dirt over a period of days. The
exact time depends on exposure to the sun, wind, and normal drainage.</p>
    <p><i>Arcane Material Component</i>: Clay and water.</p>
    <h6><a id="transport-via-plants">Transport via Plants</a></h6>
    <p className="initial"><i>Conjuration (Teleportation)
      </i></p>
    <span className="stat-block"><b>Level</b>: Drd 6</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Unlimited</span> <span className="stat-block"><b>Target</b>: You and touched objects
or other touched willing creatures</span> <span className="stat-block"><b>Duration</b>:
1 round</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You can enter any normal plant (Medium or larger) and pass any
distance to a plant of the same kind in a single round, regardless of
the distance separating the two. The entry plant must be alive. The
destination plant need not be familiar to you, but it also must be
alive. If you are uncertain of the location of a particular kind of
destination plant, you need merely designate direction and distance and
the transport via plants spell moves you as close as possible to the
desired location. If a particular destination plant is desired but the
plant is not living, the spell fails and you are ejected from the entry
plant.</p>
    <p>You can bring along objects as long as their weight
doesn&rsquo;t
exceed your maximum load. You may also bring one additional willing
Medium or smaller creature (carrying gear or objects up to its maximum
load) or its equivalent per three caster levels. Use the following
equivalents to determine the maximum number of larger creatures you can
bring along: A Large creature counts as two Medium creatures, a Huge
creature counts as two Large creatures, and so forth. All creatures to
be transported must be in contact with one another, and at least one of
those creatures must be in contact with you.</p>
    <p>You can&rsquo;t use this spell to travel through plant
creatures.</p>
    <p>The destruction of an occupied plant slays you and any
creatures you have brought along, and ejects the bodies and all carried
objects from the tree.</p>
    <h6><a id="trap-the-soul">Trap the Soul</a></h6>
    <p className="initial"><i>Conjuration (Summoning)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S, M, (F); see text</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action
or see text</span> <span className="stat-block"><b>Range</b>: Close (25
ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One creature</span>
    <span className="stat-block"><b>Duration</b>: Permanent; see text</span>
    <span className="stat-block"><b>Saving Throw</b>: See text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes; see text</span>
    <p>Trap the soul forces a creature&rsquo;s life force (and its
material
body) into a gem. The gem holds the trapped entity indefinitely or
until the gem is broken and the life force is released, which allows
the material body to reform. If the trapped creature is a powerful
creature from another plane it can be required to perform a service
immediately upon being freed. Otherwise, the creature can go free once
the gem imprisoning it is broken.</p>
    <p>Depending on the version selected, the spell can be triggered
in one of two ways.</p>
    <p><i>Spell Completion</i>: First, the spell can be completed by
speaking its final word as a standard action as if you were casting a
regular spell at the subject. This allows spell resistance (if any) and
a Will save to avoid the effect. If the creature&rsquo;s name is spoken
as
well, any spell resistance is ignored and the save DC increases by 2.
If the save or spell resistance is successful, the gem shatters.</p>
    <p><i>Trigger Object</i>: The second method is far more
insidious, for it tricks the subject into accepting a trigger object
inscribed with the final spell word, automatically placing the
creature&rsquo;s soul in the trap. To use this method, both the
creature&rsquo;s
name and the trigger word must be inscribed on the trigger object when
the gem is enspelled. A sympathy spell can also be placed on the
trigger object. As soon as the subject picks up or accepts the trigger
object, its life force is automatically transferred to the gem without
the benefit of spell resistance or a save.</p>
    <p><i>Material Component</i>: Before the actual casting of trap
the soul, you must procure a gem of at least 1,000 gp value for every
Hit Die possessed by the creature to be trapped. If the gem is not
valuable enough, it shatters when the entrapment is attempted. (While
creatures have no concept of level or Hit Dice as such, the value of
the gem needed to trap an individual can be researched. Remember that
this value can change over time as creatures gain more Hit Dice.)</p>
    <p><i>Focus (Trigger Object Only)</i>: If the trigger object
method is used, a special trigger object, prepared as described above,
is needed.</p>
    <h6><a id="tree-shape">Tree Shape</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2, Rgr 3</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 hour/level (D)</span>
    <p>By means of this spell, you are able to assume the form of a
Large living tree or shrub or a Large dead tree trunk with a small
number of limbs. The closest inspection cannot reveal that the tree in
question is actually a magically concealed creature. To all normal
tests you are, in fact, a tree or shrub, although a detect magic spell
reveals a faint transmutation on the tree. While in tree form, you can
observe all that transpires around you just as if you were in your
normal form, and your hit points and save bonuses remain unaffected.
You gain a +10 natural armor bonus to AC but have an effective
Dexterity score of 0 and a speed of 0 feet. You are immune to critical
hits while in tree form. All clothing and gear carried or worn changes
with you.</p>
    <p>You can dismiss tree shape as a free action (instead of as a
standard action).</p>
    <h6><a id="tree-stride">Tree Stride</a></h6>
    <p className="initial"><i>Conjuration (Teleportation)</i></p>
    <span className="stat-block"><b>Level</b>: Drd 5, Rgr 4</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 hour/level or until
expended; see text</span>
    <p>You gain the ability to enter trees and move from inside one
tree to inside another tree. The first tree you enter and all others
you enter must be of the same kind, must be living, and must have girth
at least equal to yours. By moving into an oak tree (for example), you
instantly know the location of all other oak trees within transport
range (see below) and may choose whether you want to pass into one or
simply step back out of the tree you moved into. You may choose to pass
to any tree of the appropriate kind within the transport range as shown
on the following table.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>Type of Tree</th>
          <th style={{
            "width": "150px"
          }}>Transport Range</th>
        </tr>
        <tr className="odd-row">
          <td>Oak, ash, yew</td>
          <td>3,000 feet</td>
        </tr>
        <tr>
          <td>Elm, linden</td>
          <td>2,000 feet</td>
        </tr>
        <tr className="odd-row">
          <td>Other deciduous</td>
          <td>1,500 feet</td>
        </tr>
        <tr>
          <td>Any coniferous</td>
          <td>1,000 feet</td>
        </tr>
        <tr className="odd-row">
          <td>All other trees</td>
          <td>500 feet</td>
        </tr>
      </tbody>
    </table>
    <p>You may move into a tree up to one time per caster level
(passing from one tree to another counts only as moving into one tree).
The spell lasts until the duration expires or you exit a tree. Each
transport is a full-round action.</p>
    <p>You can, at your option, remain within a tree without
transporting yourself, but you are forced out when the spell ends. If
the tree in which you are concealed is chopped down or burned, you are
slain if you do not exit before the process is complete.</p>
    <h6><a id="true-resurrection">True Resurrection</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Clr 9</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#raise-dead">raise dead</a>, except that you can
resurrect a creature that has been <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a> for as long as 10 years per
caster level. This spell can even bring back creatures whose bodies
have been destroyed, provided that you unambiguously identify the
deceased in some fashion (reciting the deceased&rsquo;s time and place
of
birth or death is the most common method).</p>
    <p>Upon completion of the spell, the creature is immediately
restored to full hit points, vigor, and health, with no loss of level
(or Constitution points) or prepared spells.</p>
    <p>You can revive someone killed by a death effect or someone who
has been turned into an undead creature and then destroyed. This spell
can also resurrect elementals or outsiders, but it can&rsquo;t
resurrect
constructs or undead creatures.</p>
    <p>Even true resurrection can&rsquo;t restore to life a creature
who
has died of old age.</p>
    <p><i>Material Component</i>: A sprinkle of holy water and
diamonds worth a total of at least 25,000 gp.</p>
    <h6><a id="true-seeing">True Seeing</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Drd 7, Knowledge 5,
Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Creature touched</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>You confer on the subject the ability to see all things as
they actually are. The subject sees through normal and magical
darkness, notices secret doors hidden by magic, sees the exact
locations of creatures or objects under blur or displacement effects,
sees <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> creatures or objects
normally, sees through illusions,
and sees the true form of polymorphed, changed, or transmuted things.
Further, the subject can focus its vision to see into the Ethereal
Plane (but not into extradimensional spaces). The range of true seeing
conferred is 120 feet.</p>
    <p>True seeing, however, does not penetrate solid objects. It in
no way confers X-ray vision or its equivalent. It does not negate
concealment, including that caused by fog and the like. True seeing
does not help the viewer see through mundane disguises, spot creatures
who are simply hiding, or notice secret doors hidden by mundane means.
In addition, the spell effects cannot be further enhanced with known
magic, so one cannot use true seeing through a crystal ball or in
conjunction with clairaudience/clairvoyance.</p>
    <p><i>Material Component</i>: An ointment for the eyes that costs
250 gp and is made from mushroom powder, saffron, and fat.</p>
    <h6><a id="true-strike">True Strike</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
See text</span>
    <p>You gain temporary, intuitive insight into the immediate
future during your next attack. Your next single attack roll (if it is
made before the end of the next round) gains a +20 insight bonus.
Additionally, you are not affected by the miss chance that applies to
attackers trying to strike a concealed target.</p>
    <p><i>Focus</i>: A small wooden replica of an archery target.</p>
    <h6><a id="undeath-to-death">Undeath to Death</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Area</b>: Several undead creatures
within a 40-ft.-radius burst</span> <span className="stat-block"><b>Saving
Throw</b>: Will negates</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsC.html#circle-of-death">circle of death</a>, except that
it
destroys undead creatures as noted above.</p>
    <p><i>Material Component</i>: The powder of a crushed diamond
worth at least 500 gp.</p>
    <h6><a id="undetectable-alignment">Undetectable Alignment</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Clr 2, Pal 2</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One creature or
object</span> <span className="stat-block"><b>Duration</b>: 24 hours</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>An undetectable alignment spell conceals the alignment of an
object or a creature from all forms of divination.</p>
    <h6><a id="unhallow">Unhallow</a></h6>
    <p className="initial"><i>Evocation [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Drd 5</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 24 hours</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Area</b>:
40-ft. radius emanating
from the touched point</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: See
text</span> <span className="stat-block"><b>Spell Resistance</b>: See text</span>
    <p>Unhallow makes a particular site, building, or structure an
unholy site. This has three major effects.</p>
    <p>First, the site or structure is guarded by a magic circle
against good effect.</p>
    <p>Second, all turning checks made to <a href="specialAttacks.html#turn" style={{
        "color": "rgb(87, 158, 182)"
      }}>turn
undead</a> take a &ndash;4
penalty, and turning checks to rebuke undead gain a +4 profane bonus.
Spell resistance does not apply to this effect. (This provision does
not apply to the druid version of the spell.)</p>
    <p>Finally, you may choose to fix a single spell effect to the
unhallowed site. The spell effect lasts for one year and functions
throughout the entire site, regardless of its normal duration and area
or effect. You may designate whether the effect applies to all
creatures, creatures that share your faith or alignment, or creatures
that adhere to another faith or alignment. At the end of the year, the
chosen effect lapses, but it can be renewed or replaced simply by
casting unhallow again.</p>
    <p>Spell effects that may be tied to an unhallowed site include
aid, bane, bless, cause fear, darkness, daylight, death ward, deeper
darkness, detect magic, detect good, dimensional anchor, discern lies,
dispel magic, endure elements, freedom of movement, invisibility purge,
protection from energy, remove fear, resist energy, silence, tongues,
and zone of truth.</p>
    <p>Saving throws and spell resistance might apply to these
spells&rsquo; effects. (See the individual spell descriptions for
details.)</p>
    <p>An area can receive only one unhallow spell (and its
associated spell effect) at a time.</p>
    <p>Unhallow counters but does not dispel hallow.</p>
    <p><i>Material Component</i>: Herbs, oils, and incense worth at
least 1,000 gp, plus 1,000 gp per level of the spell to be tied to the
unhallowed area.</p>
    <h6><a id="unholy-aura">Unholy Aura</a></h6>
    <p className="initial"><i>Abjuration [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 8, Evil 8</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 20 ft.</span> <span className="stat-block"><b>Targets</b>: One creature/level in a
20-ft.-radius burst centered on you</span> <span className="stat-block"><b>Duration</b>:
1 round/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
See text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(harmless)</span>
    <p>A malevolent darkness surrounds the subjects, protecting them
from attacks, granting them resistance to spells cast by good
creatures, and weakening good creatures when they strike the subjects.
This abjuration has four effects.</p>
    <p>First, each warded creature gains a +4 deflection bonus to AC
and a +4 resistance bonus on saves. Unlike the effect of protection
from good, this benefit applies against all attacks, not just against
attacks by good creatures.</p>
    <p>Second, a warded creature gains spell resistance 25 against
good spells and spells cast by good creatures.</p>
    <p>Third, the abjuration blocks possession and mental influence,
just as protection from good does.</p>
    <p>Finally, if a good creature succeeds on a melee attack against
a warded creature, the offending attacker takes 1d6 points of temporary
Strength damage (Fortitude negates).</p>
    <p><i>Focus</i>: A tiny reliquary containing some sacred relic,
such as a piece of parchment from an unholy text. The reliquary costs
at least 500 gp.</p>
    <h6><a id="unholy-blight">Unholy Blight</a></h6>
    <p className="initial"><i>Evocation [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Evil 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: 20-ft.-radius
spread</span> <span className="stat-block"><b>Duration</b>: Instantaneous
(1d4
rounds); see text</span> <span className="stat-block"><b>Saving Throw</b>:
Will partial</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>You call up unholy power to smite your enemies. The power
takes the form of a cold, cloying miasma of greasy darkness.</p>
    <p>Only good and neutral (not evil) creatures are harmed by the
spell.</p>
    <p>The spell deals 1d8 points of damage per two caster levels
(maximum 5d8) to a good creature (or 1d6 per caster level, maximum
10d6, to a good outsider) and causes it to be <a href="abilitiesAndConditions.html#sickened" style={{
        "color": "rgb(87, 158, 182)"
      }}>sickened</a> for 1d4 rounds.
A successful Will save reduces damage to half and negates the sickened
effect. The effects cannot be negated by remove disease or heal, but
remove curse is effective.</p>
    <p>The spell deals only half damage to creatures who are neither
evil nor good, and they are not sickened. Such a creature can reduce
the damage in half again (down to one-quarter) with a successful Will
save.</p>
    <h6><a id="unseen-servant">Unseen Servant</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: One invisible,
mindless,
shapeless servant</span> <span className="stat-block"><b>Duration</b>: 1
hour/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>An unseen servant is an <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>, mindless, shapeless
force
that performs simple tasks at your command. It can run and fetch
things, open unstuck doors, and hold chairs, as well as clean and mend.
The servant can perform only one activity at a time, but it repeats the
same activity over and over again if told to do so as long as you
remain within range. It can open only normal doors, drawers, lids, and
the like. It has an effective Strength score of 2 (so it can lift 20
pounds or drag 100 pounds). It can trigger traps and such, but it can
exert only 20 pounds of force, which is not enough to activate certain
pressure plates and other devices. It can&rsquo;t perform any task that
requires a skill check with a DC higher than 10 or that requires a
check using a skill that can&rsquo;t be used untrained. Its speed is 15
feet.</p>
    <p>The servant cannot attack in any way; it is never allowed an
attack roll. It cannot be killed, but it dissipates if it takes 6
points of damage from area attacks. (It gets no saves against attacks.)
If you attempt to send it beyond the spell&rsquo;s range (measured from
your
current position), the servant ceases to exist.</p>
    <p><i>Material Component</i>: A piece of string and a bit of wood.</p>
    <h6><a id="vampiric-touch">Vampiric Touch</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Living creature touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous/1 hour;
see text</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You must succeed on a melee touch attack. Your touch deals 1d6
points of damage per two caster levels (maximum 10d6). You gain
temporary hit points equal to the damage you deal. However, you
can&rsquo;t
gain more than the subject&rsquo;s current hit points +10, which is
enough to
kill the subject. The temporary hit points disappear 1 hour later.</p>
    <h6><a id="veil">Veil</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Targets</b>: One or more
creatures,
no two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: Concentration + 1
hour/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes; see
text</span>
    <p>You instantly change the appearance of the subjects and then
maintain that appearance for the spell&rsquo;s duration. You can make
the
subjects appear to be anything you wish. The subjects look, feel, and
smell just like the creatures the spell makes them resemble. Affected
creatures resume their normal appearances if slain. You must succeed on
a <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a>
check to duplicate the appearance of a specific individual.
This spell gives you a +10 bonus on the check.</p>
    <p>Unwilling targets can negate the spell&rsquo;s effect on them
by
making Will saves or with spell resistance. Those who interact with the
subjects can attempt Will disbelief saves to see through the glamer,
but spell resistance doesn&rsquo;t help.</p>
    <h6><a id="ventriloquism">Ventriloquism</a></h6>
    <p className="initial"><i>Illusion (Figment)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Intelligible
sound,
usually speech</span> <span className="stat-block"><b>Duration</b>: 1
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will disbelief (if
interacted with)</span> <span className="stat-block"><b>Spell Resistance</b>:
No</span>
    <p>You can make your voice (or any sound that you can normally
make vocally) seem to issue from someplace else. You can speak in any
language you know. With respect to such voices and sounds, anyone who
hears the sound and rolls a successful save recognizes it as illusory
(but still hears it).</p>
    <p><i>Focus</i>: A parchment rolled up into a small cone.</p>
    <h6><a id="virtue">Virtue</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 0, Drd 0, Pal 1</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 1
min.</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude
negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The subject gains 1 temporary hit point.</p>
    <h6><a id="vision">Vision</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S, M, XP</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#legend-lore">legend lore</a>, except that it works
more quickly but produces some strain on you. You pose a question about
some person, place, or object, then cast the spell. If the person or
object is at hand or if you are in the place in question, you receive a
vision about it by succeeding on a caster level check (1d20 +1 per
caster level; maximum +25) against DC 20. If only detailed information
on the person, place, or object is known, the DC is 25, and the
information gained is incomplete. If only rumors are known, the DC is
30, and the information gained is vague.</p>
    <p><i>XP Cost</i>: 100 XP.</p>
    <h6><a id="wail-of-the-banshee">Wail of the Banshee</a></h6>
    <p className="initial"><i>Necromancy [Death, Sonic]</i></p>
    <span className="stat-block"><b>Level</b>: Death 9, Sor/Wiz 9</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Area</b>: One living
creature/level
within a 40-ft.-radius spread</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>You emit a terrible scream that kills creatures that hear it
(except for yourself). Creatures closest to the point of origin are
affected first.</p>
    <h6><a id="wall-of-fire">Wall of Fire</a></h6>
    <p className="initial"><i>Evocation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 5, Fire 4, Sor/Wiz 4</span>
    <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Opaque sheet
of flame up
to 20 ft. long/level or a ring of fire with a radius of up to 5 ft. per
two levels; either form 20 ft. high</span> <span className="stat-block"><b>Duration</b>:
Concentration + 1
round/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>An immobile, blazing curtain of shimmering violet fire springs
into existence. One side of the wall, selected by you, sends forth
waves of heat, dealing 2d4 points of fire damage to creatures within 10
feet and 1d4 points of fire damage to those past 10 feet but within 20
feet. The wall deals this damage when it appears and on your turn each
round to all creatures in the area. In addition, the wall deals 2d6
points of fire damage +1 point of fire damage per caster level (maximum
+20) to any creature passing through it. The wall deals double damage
to undead creatures.</p>
    <p>If you evoke the wall so that it appears where creatures are,
each creature takes damage as if passing through the wall. If any
5-foot length of wall takes 20 points of cold damage or more in 1
round, that length goes out. (Do not divide cold damage by 4, as normal
for objects.)</p>
    <p>Wall of fire can be made permanent with a permanency spell. A
permanent wall of fire that is extinguished by cold damage becomes
inactive for 10 minutes, then reforms at normal strength.</p>
    <p><i>Arcane Material Component</i>: A small piece of phosphorus.</p>
    <h6><a id="wall-of-force">Wall of Force</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Wall whose area
is up to
one 10-ft. square/level</span> <span className="stat-block"><b>Duration</b>:
1 round /level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>A wall of force spell creates an <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> wall of force. The
wall cannot move, it is immune to damage of all kinds, and it is
unaffected by most spells, including dispel magic. However,
disintegrate immediately destroys it, as does a rod of cancellation, a
sphere of annihilation, or a mage&rsquo;s disjunction spell. Breath
weapons
and spells cannot pass through the wall in either direction, although
dimension door, teleport, and similar effects can bypass the barrier.
It blocks ethereal creatures as well as material ones (though ethereal
creatures can usually get around the wall by floating under or over it
through material floors and ceilings). Gaze attacks can operate through
a wall of force.</p>
    <p>The caster can form the wall into a flat, vertical plane whose
area is up to one 10- foot square per level. The wall must be
continuous and unbroken when formed. If its surface is broken by any
object or creature, the spell fails.</p>
    <p>Wall of force can be made permanent with a permanency spell.</p>
    <p><i>Material Component</i>: A pinch of powder made from a clear
gem.</p>
    <h6><a id="wall-of-ice">Wall of Ice</a></h6>
    <p className="initial"><i>Evocation [Cold]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Anchored
plane of ice, up
to one 10-ft. square/level, or hemisphere of ice with a radius of up to
3 ft. + 1 ft./level</span> <span className="stat-block"><b>Duration</b>: 1
min./level</span> <span className="stat-block"><b>Saving Throw</b>: Reflex
negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell creates an anchored plane of ice or a hemisphere of
ice, depending on the version selected. A wall of ice cannot form in an
area occupied by physical objects or creatures. Its surface must be
smooth and unbroken when created. Any creature adjacent to the wall
when it is created may attempt a Reflex save to disrupt the wall as it
is being formed. A successful save indicates that the spell
automatically fails. Fire can melt a wall of ice, and it deals full
damage to the wall (instead of the normal half damage taken by
objects). Suddenly melting a wall of ice creates a great cloud of
steamy fog that lasts for 10 minutes.</p>
    <p><i>Ice Plane</i>: A sheet of strong, hard ice appears. The
wall is 1 inch thick per caster level. It covers up to a 10-foot-square
area per caster level (so a 10th-level wizard can create a wall of ice
100 feet long and 10 feet high, a wall 50 feet long and 20 feet high,
or some other combination of length and height that does not exceed
1,000 square feet). The plane can be oriented in any fashion as long as
it is anchored. A vertical wall need only be anchored on the floor,
while a horizontal or slanting wall must be anchored on two opposite
sides.</p>
    <p>Each 10-foot square of wall has 3 hit points per inch of
thickness. Creatures can hit the wall automatically. A section of wall
whose hit points drop to 0 is breached. If a creature tries to break
through the wall with a single attack, the DC for the Strength check is
15 + caster level.</p>
    <p>Even when the ice has been broken through, a sheet of frigid
air remains. Any creature stepping through it (including the one who
broke through the wall) takes 1d6 points of cold damage +1 point per
caster level (no save).</p>
    <p><i>Hemisphere</i>: The wall takes the form of a hemisphere
whose maximum radius is 3 feet + 1 foot per caster level. The
hemisphere is as hard to break through as the ice plane form, but it
does not deal damage to those who go through a breach.</p>
    <p><i>Material Component</i>: A small piece of quartz or similar
rock crystal.</p>
    <h6><a id="wall-of-iron">Wall of Iron</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Iron wall
whose area is
up to one 5-ft. square/level; see text</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: See
text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You cause a flat, vertical iron wall to spring into being. The
wall inserts itself into any surrounding nonliving material if its area
is sufficient to do so. The wall cannot be conjured so that it occupies
the same space as a creature or another object. It must always be a
flat plane, though you can shape its edges to fit the available space.</p>
    <p>A wall of iron is 1 inch thick per four caster levels. You can
double the wall&rsquo;s area by halving its thickness. Each 5- foot
square of
the wall has 30 hit points per inch of thickness and hardness 10. A
section of wall whose hit points drop to 0 is breached. If a creature
tries to break through the wall with a single attack, the DC for the
Strength check is 25 + 2 per inch of thickness.</p>
    <p>If you desire, the wall can be created vertically resting on a
flat surface but not attached to the surface, so that it can be tipped
over to fall on and crush creatures beneath it. The wall is 50% likely
to tip in either direction if left unpushed. Creatures can push the
wall in one direction rather than letting it fall randomly. A creature
must make a DC 40 Strength check to push the wall over. Creatures with
room to flee the falling wall may do so by making successful Reflex
saves. Any Large or smaller creature that fails takes 10d6 points of
damage. The wall cannot crush Huge and larger creatures.</p>
    <p>Like any iron wall, this wall is subject to rust, perforation,
and other natural phenomena.</p>
    <p><i>Material Component</i>: A small piece of sheet iron plus
gold dust worth 50 gp (1 pound of gold dust).</p>
    <h6><a id="wall-of-stone">Wall of Stone</a></h6>
    <p className="initial"><i>Conjuration (Creation) [Earth]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Drd 6, Earth 5,
Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Stone wall
whose area is
up to one 5-ft. square/level (S)</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: See
text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell creates a wall of rock that merges into adjoining
rock surfaces. A wall of stone is 1 inch thick per four caster levels
and composed of up to one 5-foot square per level. You can double the
wall&rsquo;s area by halving its thickness. The wall cannot be conjured
so
that it occupies the same space as a creature or another object.</p>
    <p>Unlike a wall of iron, you can create a wall of stone in
almost any shape you desire. The wall created need not be vertical, nor
rest upon any firm foundation; however, it must merge with and be
solidly supported by existing stone. It can be used to bridge a chasm,
for instance, or as a ramp. For this use, if the span is more than 20
feet, the wall must be arched and buttressed. This requirement reduces
the spell&rsquo;s area by half. The wall can be crudely shaped to allow
crenellations, battlements, and so forth by likewise reducing the area.</p>
    <p>Like any other stone wall, this one can be destroyed by a
disintegrate spell or by normal means such as breaking and chipping.
Each 5-foot square of the wall has 15 hit points per inch of thickness
and hardness 8. A section of wall whose hit points drop to 0 is
breached. If a creature tries to break through the wall with a single
attack, the DC for the Strength check is 20 + 2 per inch of thickness.</p>
    <p>It is possible, but difficult, to trap mobile opponents within
or under a wall of stone, provided the wall is shaped so it can hold
the creatures. Creatures can avoid entrapment with successful Reflex
saves.</p>
    <p><i>Arcane Material Component</i>: A small block of granite.</p>
    <h6><a id="wall-of-thorns">Wall of Thorns</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Drd 5, Plant 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Wall of
thorny brush, up
to one 10-ft. cube/level (S)</span> <span className="stat-block"><b>Duration</b>:
10 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>A wall of thorns spell creates a barrier of very tough,
pliable, tangled brush bearing needle-sharp thorns as long as a
human&rsquo;s
finger. Any creature forced into or attempting to move through a wall
of thorns takes slashing damage per round of movement equal to 25 minus
the creature&rsquo;s AC. Dexterity and dodge bonuses to AC do not count
for
this calculation. (Creatures with an Armor Class of 25 or higher,
without considering Dexterity and dodge bonuses, take no damage from
contact with the wall.)</p>
    <p>You can make the wall as thin as 5 feet thick, which allows
you to shape the wall as a number of 10-by-10-by-5-foot blocks equal to
twice your caster level. This has no effect on the damage dealt by the
thorns, but any creature attempting to break through takes that much
less time to force its way through the barrier.</p>
    <p>Creatures can force their way slowly through the wall by
making a Strength check as a full-round action. For every 5 points by
which the check exceeds 20, a creature moves 5 feet (up to a maximum
distance equal to its normal land speed). Of course, moving or
attempting to move through the thorns incurs damage as described above.
A creature trapped in the thorns can choose to remain motionless in
order to avoid taking any more damage.</p>
    <p>Any creature within the area of the spell when it is cast
takes damage as if it had moved into the wall and is caught inside. In
order to escape, it must attempt to push its way free, or it can wait
until the spell ends. Creatures with the ability to pass through
overgrown areas unhindered can pass through a wall of thorns at normal
speed without taking damage.</p>
    <p>A wall of thorns can be breached by slow work with edged
weapons. Chopping away at the wall creates a safe passage 1 foot deep
for every 10 minutes of work. Normal fire cannot harm the barrier, but
magical fire burns it away in 10 minutes.</p>
    <p>Despite its appearance, a wall of thorns is not actually a
living plant, and thus is unaffected by spells that affect plants.</p>
    <h6><a id="warp-wood">Warp Wood</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: 1 Small wooden
object/level, all within a 20-ft. radius</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>You cause wood to bend and warp, permanently destroying its
straightness, form, and strength. A warped door springs open (or
becomes stuck, requiring a Strength check to open, at your option). A
boat or ship springs a leak. Warped ranged weapons are useless. A
warped melee weapon causes a &ndash;4 penalty on attack rolls.</p>
    <p>You may warp one Small or smaller object or its equivalent per
caster level. A Medium object counts as two Small objects, a Large
object as four, a Huge object as eight, a Gargantuan object as sixteen,
and a Colossal object as thirty-two.</p>
    <p>Alternatively, you can unwarp wood (effectively warping it
back to normal) with this spell, straightening wood that has been
warped by this spell or by other means. Make whole, on the other hand,
does no good in repairing a warped item.</p>
    <p>You can combine multiple consecutive warp wood spells to warp
(or unwarp) an object that is too large for you to warp with a single
spell. </p>
    <p>Until the object is completely warped, it suffers no ill
effects.</p>
    <h6><a id="water-breathing">Water Breathing</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Drd 3, Sor/Wiz 3,
Water 3</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Living creatures touched</span> <span className="stat-block"><b>Duration</b>: 2 hours/level; see text</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The transmuted creatures can breathe water freely. Divide the
duration evenly among all the creatures you touch.</p>
    <p>The spell does not make creatures unable to breathe air.</p>
    <p><i>Arcane Material Component</i>: A short reed or piece of
straw.</p>
    <h6><a id="water-walk">Water Walk</a></h6>
    <p className="initial"><i>Transmutation [Water]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Rgr 3</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
One touched
creature/level</span> <span className="stat-block"><b>Duration</b>: 10
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The transmuted creatures can tread on any liquid as if it were
firm ground. Mud, oil, snow, quicksand, running water, ice, and even
lava can be traversed easily, since the subjects&rsquo; feet hover an
inch or
two above the surface. (Creatures crossing molten lava still take
damage from the heat because they are near it.) The subjects can walk,
run, <a href="specialAttacks.html#charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>charge</a>, or otherwise move across
the surface as if it were normal
ground.</p>
    <p>If the spell is cast underwater (or while the subjects are
partially or wholly submerged in whatever liquid they are in), the
subjects are borne toward the surface at 60 feet per round until they
can stand on it.</p>
    <h6><a id="waves-of-exhaustion">Waves of Exhaustion</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 60 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped burst</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: No</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Waves of negative energy cause all living creatures in the
spell&rsquo;s area to become <a href="abilitiesAndConditions.html#exhausted" style={{
        "color": "rgb(87, 158, 182)"
      }}>exhausted</a>. This spell has no
effect on a
creature that is already exhausted.</p>
    <h6><a id="waves-of-fatigue">Waves of Fatigue</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 30 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped burst</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: No</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Waves of negative energy render all living creatures in the
spell&rsquo;s area <a href="abilitiesAndConditions.html#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigued</a>. This spell has no
effect on a creature that is
already fatigued.</p>
    <h6><a id="web">Web</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Webs in a
20-ft.-radius
spread</span> <span className="stat-block"><b>Duration</b>: 10 min./level
(D)</span> <span className="stat-block"><b>Saving Throw</b>: Reflex
negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Web creates a many-layered mass of strong, sticky strands.
These strands trap those caught in them. The strands are similar to
spider webs but far larger and tougher. These masses must be anchored
to two or more solid and diametrically opposed points or else the web
collapses upon itself and disappears. Creatures caught within a web
become <a href="abilitiesAndConditions.html#entangled" style={{
        "color": "rgb(87, 158, 182)"
      }}>entangled</a> among the gluey
fibers. Attacking a creature in a web
won&rsquo;t cause you to become entangled.</p>
    <p>Anyone in the effect&rsquo;s area when the spell is cast must
make a
Reflex save. If this save succeeds, the creature is entangled, but not
prevented from moving, though moving is more difficult than normal for
being entangled (see below). If the save fails, the creature is
entangled and can&rsquo;t move from its space, but can break loose by
spending 1 round and making a DC 20 Strength check or a DC 25 <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape
Artist</a> check. Once loose (either by making the initial Reflex save
or a
later Strength check or Escape Artist check), a creature remains
entangled, but may move through the web very slowly. Each round devoted
to moving allows the creature to make a new Strength check or Escape
Artist check. The creature moves 5 feet for each full 5 points by which
the check result exceeds 10.</p>
    <p>If you have at least 5 feet of web between you and an
opponent, it provides cover. If you have at least 20 feet of web
between you, it provides total cover.</p>
    <p>The strands of a web spell are flammable. A magic flaming
sword can slash them away as easily as a hand brushes away cobwebs. Any
fire can set the webs alight and burn away 5 square feet in 1 round.
All creatures within flaming webs take 2d4 points of fire damage from
the flames.</p>
    <p>Web can be made permanent with a permanency spell. A permanent
web that is damaged (but not destroyed) regrows in 10 minutes.</p>
    <p><i>Material Component</i>: A bit of spider web.</p>
    <h6><a id="weird">Weird</a></h6>
    <p className="initial"><i>Illusion (Phantasm) [Fear, Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 9</span> <span className="stat-block"><b>Targets</b>: Any number of creatures,
no two of which can be more than 30 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#phantasmal-killer">phantasmal killer</a>, except it can
affect more than one creature. Only the affected creatures see the
phantasmal creatures attacking them, though you see the attackers as
shadowy shapes.</p>
    <p>If a subject&rsquo;s Fortitude save succeeds, it still takes
3d6
points of damage and is <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a> for 1 round. The subject
also takes 1d4
points of temporary Strength damage.</p>
    <h6><a id="whirlwind">Whirlwind</a></h6>
    <p className="initial"><i>Evocation [Air]</i></p>
    <span className="stat-block"><b>Level</b>: Air 8, Drd 8</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Effect</b>: Cyclone 10
ft. wide at
base, 30 ft. wide at top, and 30 ft. tall</span> <span className="stat-block"><b>Duration</b>: 1 round/level (D)</span> <span className="stat-block"><b>Saving Throw</b>: Reflex negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell creates a powerful cyclone of raging wind that
moves through the air, along the ground, or over water at a speed of 60
feet per round. You can concentrate on controlling the cyclone&rsquo;s
every
movement or specify a simple program. Directing the cyclone&rsquo;s
movement
or changing its programmed movement is a standard action for you. The
cyclone always moves during your turn. If the cyclone exceeds the
spell&rsquo;s range, it moves in a random, uncontrolled fashion for 1d3
rounds and then dissipates. (You can&rsquo;t regain control of the
cyclone,
even if comes back within range.)</p>
    <p>Any Large or smaller creature that comes in contact with the
spell effect must succeed on a Reflex save or take 3d6 points of
damage. A Medium or smaller creature that fails its first save must
succeed on a second one or be picked up bodily by the cyclone and held
suspended in its powerful winds, taking 1d8 points of damage each round
on your turn with no save allowed. You may direct the cyclone to eject
any carried creatures whenever you wish, depositing the hapless souls
wherever the cyclone happens to be when they are released.</p>
    <h6><a id="whispering-wind">Whispering Wind</a></h6>
    <p className="initial"><i>Transmutation [Air]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 1 mile/level</span> <span className="stat-block"><b>Area</b>: 10-ft.-radius spread</span> <span className="stat-block"><b>Duration</b>: No more than 1
hour/level or until discharged (destination is reached)</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You send a message or sound on the wind to a designated spot.
The whispering wind travels to a specific location within range that is
familiar to you, provided that it can find a way to the location. A
whispering wind is as gentle and unnoticed as a zephyr until it reaches
the location. It then delivers its whisper-quiet message or other
sound. Note that the message is delivered regardless of whether anyone
is present to hear it. The wind then dissipates.</p>
    <p>You can prepare the spell to bear a message of no more than
twenty-five words, cause the spell to deliver other sounds for 1 round,
or merely have the whispering wind seem to be a faint stirring of the
air. You can likewise cause the whispering wind to move as slowly as 1
mile per hour or as quickly as 1 mile per 10 minutes.</p>
    <p>When the spell reaches its objective, it swirls and remains in
place until the message is delivered. As with magic mouth, whispering
wind cannot speak verbal components, use command words, or activate
magical effects.</p>
    <h6><a id="wind-walk">Wind Walk</a></h6>
    <p className="initial"><i>Transmutation [Air]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Drd 7</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
You and one touched
creature per three levels</span> <span className="stat-block"><b>Duration</b>:
1 hour/level (D); see
text</span> <span className="stat-block"><b>Saving Throw</b>: No and Will
negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: No
and Yes
(harmless)</span>
    <p>You alter the substance of your body to a cloudlike vapor (as
the gaseous form spell) and move through the air, possibly at great
speed. You can take other creatures with you, each of which acts
independently.</p>
    <p>Normally, a wind walker flies at a speed of 10 feet with
perfect maneuverability. If desired by the subject, a magical wind
wafts a wind walker along at up to 600 feet per round (60 mph) with
poor maneuverability. Wind walkers are not <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> but rather appear
misty and translucent. If fully clothed in white, they are 80% likely
to be mistaken for clouds, fog, vapors, or the like.</p>
    <p>A wind walker can regain its physical form as desired and
later resume the cloud form. Each change to and from vaporous form
takes 5 rounds, which counts toward the duration of the spell (as does
any time spent in physical form). As noted above, you can dismiss the
spell, and you can even dismiss it for individual wind walkers and not
others.</p>
    <p>For the last minute of the spell&rsquo;s duration, a wind
walker in
cloud form automatically descends 60 feet per round (for a total of 600
feet), though it may descend faster if it wishes. This descent serves
as a warning that the spell is about to end.</p>
    <h6><a id="wind-wall">Wind Wall</a></h6>
    <p className="initial"><i>Evocation [Air]</i></p>
    <span className="stat-block"><b>Level</b>: Air 2, Clr 3, Drd 3, Rgr
2, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S,
M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard
action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. +
10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Wall up to
10 ft./level
long and 5 ft./level high (S)</span> <span className="stat-block"><b>Duration</b>:
1 round/level</span> <span className="stat-block"><b>Saving Throw</b>:
None; see text</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>An <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> vertical curtain of
wind appears. It is 2 feet
thick and of considerable strength. It is a roaring blast sufficient to
blow away any bird smaller than an eagle, or tear papers and similar
materials from unsuspecting hands. (A Reflex save allows a creature to
maintain its grasp on an object.) Tiny and Small flying creatures
cannot pass through the barrier. Loose materials and cloth garments fly
upward when caught in a wind wall. Arrows and bolts are deflected
upward and miss, while any other normal ranged weapon passing through
the wall has a 30% miss chance. (A giant-thrown boulder, a siege engine
projectile, and other massive ranged weapons are not affected.) Gases,
most gaseous breath weapons, and creatures in gaseous form cannot pass
through the wall (although it is no barrier to <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> creatures).</p>
    <p>While the wall must be vertical, you can shape it in any
continuous path along the ground that you like. It is possible to
create cylindrical or square wind walls to enclose specific points. </p>
    <p><i>Arcane Material Component</i>: A tiny fan and a feather of
exotic origin.</p>
    <h6><a id="wish">Wish</a></h6>
    <p className="initial"><i>Universal</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 9</span> <span className="stat-block"><b>Components</b>: V, XP</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: See text</span> <span style={{
      "fontWeight": "bold"
    }}>Target, Effect, or Area:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>Wish is the mightiest spell a wizard or sorcerer can cast. By
simply speaking aloud, you can alter reality to better suit you.</p>
    <p>Even wish, however, has its limits.</p>
    <p>A wish can produce any one of the following effects.</p>
    <ul>
      <li>
        <p>Duplicate any wizard or sorcerer spell of 8th level or
lower, provided the spell is not of a school prohibited to you.</p>
      </li>
      <li>
        <p>Duplicate any other spell of 6th level or lower, provided
the spell is not of a school prohibited to you.</p>
      </li>
      <li>
        <p>Duplicate any wizard or sorcerer spell of 7th level or
lower even if it&rsquo;s of a prohibited school.</p>
      </li>
      <li>
        <p>Duplicate any other spell of 5th level or lower even if
it&rsquo;s of a prohibited school. </p>
      </li>
      <li>
        <p>Undo the harmful effects of many other spells, such as
geas/quest or insanity.</p>
      </li>
      <li>
        <p>Create a nonmagical item of up to 25,000 gp in value.</p>
      </li>
      <li>
        <p>Create a magic item, or add to the powers of an existing
magic item.</p>
      </li>
      <li>
        <p>Grant a creature a +1 inherent bonus to an ability score.
Two to five wish spells cast in immediate succession can grant a
creature a +2 to +5 inherent bonus to an ability score (two wishes for
a +2 inherent bonus, three for a +3 inherent bonus, and so on).
Inherent bonuses are instantaneous, so they cannot be dispelled. Note:
An inherent bonus may not exceed +5 for a single ability score, and
inherent bonuses to a particular ability score do not stack, so only
the best one applies.</p>
      </li>
      <li>
        <p>Remove injuries and afflictions. A single wish can aid one
creature per caster level, and all subjects are cured of the same kind
of affliction. For example, you could heal all the damage you and your
companions have taken, or remove all poison effects from everyone in
the party, but not do both with the same wish. A wish can never restore
the experience point loss from casting a spell or the level or
Constitution loss from being raised from the dead.</p>
      </li>
      <li>
        <p>Revive the dead. A wish can bring a <a href="abilitiesAndConditions.html#dead" style={{
            "color": "rgb(87, 158, 182)"
          }}>dead</a> creature back to
life by duplicating a resurrection spell. A wish can revive a dead
creature whose body has been destroyed, but the task takes two wishes,
one to recreate the body and another to infuse the body with life
again. A wish cannot prevent a character who was brought back to life
from losing an experience level.</p>
      </li>
      <li>
        <p>Transport travelers. A wish can lift one creature per
caster level from anywhere on any plane and place those creatures
anywhere else on any plane regardless of local conditions. An unwilling
target gets a Will save to negate the effect, and spell resistance (if
any) applies.</p>
      </li>
      <li>
        <p>Undo misfortune. A wish can undo a single recent event.
The wish forces a reroll of any roll made within the last round
(including your last turn). Reality reshapes itself to accommodate the
new result. For example, a wish could undo an opponent&rsquo;s
successful
save, a foe&rsquo;s successful critical hit (either the attack roll or
the
critical roll), a friend&rsquo;s failed save, and so on. The reroll,
however,
may be as bad as or worse than the original roll. An unwilling target
gets a Will save to negate the effect, and spell resistance (if any)
applies.</p>
      </li>
    </ul>
    <p>You may try to use a wish to produce greater effects than
these, but doing so is dangerous. (The wish may pervert your intent
into a literal but undesirable fulfillment or only a partial
fulfillment.)</p>
    <p>Duplicated spells allow saves and spell resistance as normal
(but save DCs are for 9th-level spells).</p>
    <p><i>Material Component</i>: When a wish duplicates a spell with
a material component that costs more than 10,000 gp, you must provide
that component.</p>
    <p><i>XP Cost</i>: The minimum XP cost for casting wish is 5,000
XP. When a wish duplicates a spell that has an XP cost, you must pay
5,000 XP or that cost, whichever is more. When a wish creates or
improves a magic item, you must pay twice the normal XP cost for
crafting or improving the item, plus an additional 5,000 XP.</p>
    <h6><a id="wood-shape">Wood Shape</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
One touched piece of wood
no larger than 10 cu. ft. + 1 cu. ft./level</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>Wood shape enables you to form one existing piece of wood into
any shape that suits your purpose. While it is possible to make crude
coffers, doors, and so forth, fine detail isn&rsquo;t possible. There
is a
30% chance that any shape that includes moving parts simply
doesn&rsquo;t
work.</p>
    <h6><a id="word-of-chaos">Word of Chaos</a></h6>
    <p className="initial"><i>Evocation [Chaotic, Sonic]</i></p>
    <span className="stat-block"><b>Level</b>: Chaos 7, Clr 7</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 40 ft.</span> <span className="stat-block"><b>Area</b>: Nonchaotic creatures in a
40-ft.- radius spread centered on you</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
None or Will
negates; see text</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Any nonchaotic creature within the area who hears the word of
chaos suffers the following ill effects.</p>
    <p>The effects are cumulative and concurrent. No saving throw is
allowed against these effects.</p>
    <p><i>Deafened</i>: The creature is <a href="abilitiesAndConditions.html#deafend" style={{
        "color": "rgb(87, 158, 182)"
      }}>deafened</a> for 1d4 rounds.</p>
    <p><i>Stunned</i>: The creature is <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a> for 1 round.</p>
    <p><i>Confused</i>: The creature is <a href="abilitiesAndConditions.html#confused" style={{
        "color": "rgb(87, 158, 182)"
      }}>confused</a>, as by the confusion
spell, for 1d10 minutes. This is a mind-affecting enchantment effect.</p>
    <p><i>Killed</i>: Living creatures die. Undead creatures are
destroyed.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>HD</th>
          <th>Effect</th>
        </tr>
        <tr className="odd-row">
          <td>Equal to caster level</td>
          <td>Deafened</td>
        </tr>
        <tr>
          <td>Up to caster level &ndash;1</td>
          <td>Stunned, deafened</td>
        </tr>
        <tr className="odd-row">
          <td>Up to caster level &ndash;5</td>
          <td>Confused, stunned, deafened</td>
        </tr>
        <tr>
          <td className="last-row">Up to caster level &ndash;10</td>
          <td className="last-row">Killed, confused, stunned, deafened</td>
        </tr>
      </tbody>
    </table>
    <p>Furthermore, if you are on your home plane when you cast this
spell, nonchaotic extraplanar creatures within the area are instantly
banished back to their home planes. Creatures so banished cannot return
for at least 24 hours. This effect takes place regardless of whether
the creatures hear the word of chaos. The banishment effect allows a
Will save (at a &ndash;4 penalty) to negate.</p>
    <p>Creatures whose HD exceed your caster level are unaffected by
word of chaos.</p>
    <h6><a id="word-of-recall">Word of Recall</a></h6>
    <p className="initial"><i>Conjuration (Teleportation)</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Drd 8</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Unlimited</span> <span className="stat-block"><b>Target</b>: You and touched objects
or other willing creatures</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
None or Will
negates (harmless, object)</span> <span className="stat-block"><b>Spell
Resistance</b>: No or Yes
(harmless, object)</span>
    <p>Word of recall teleports you instantly back to your sanctuary
when the word is uttered. You must designate the sanctuary when you
prepare the spell, and it must be a very familiar place. The actual
point of arrival is a designated area no larger than 10 feet by 10
feet. You can be transported any distance within a plane but cannot
travel between planes. You can transport, in addition to yourself, any
objects you carry, as long as their weight doesn&rsquo;t exceed your
maximum
load. You may also bring one additional willing Medium or smaller
creature (carrying gear or objects up to its maximum load) or its
equivalent per three caster levels. A Large creature counts as two
Medium creatures, a Huge creature counts as two Large creatures, and so
forth. All creatures to be transported must be in contact with one
another, and at least one of those creatures must be in contact with
you. Exceeding this limit causes the spell to fail.</p>
    <p>An unwilling creature can&rsquo;t be teleported by word of
recall.
Likewise, a creature&rsquo;s Will save (or spell resistance) prevents
items
in its possession from being teleported. Unattended, nonmagical objects
receive no saving throw.</p>
    <h6><a id="zone-of-silence">Zone of Silence</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Area</b>: 5-ft.-radius emanation
centered on you</span> <span className="stat-block"><b>Duration</b>: 1
hour/level (D)</span>
    <p>By casting zone of silence, you manipulate sound waves in your
immediate vicinity so that you and those within the spell&rsquo;s area
can
converse normally, yet no one outside can hear your voices or any other
noises from within, including language-dependent or sonic spell
effects. This effect is centered on you and moves with you. Anyone who
enters the zone immediately becomes subject to its effects, but those
who leave are no longer affected. Note, however, that a successful <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
check to read lips can still reveal what&rsquo;s said inside a zone of
silence.</p>
    <h6><a id="zone-of-truth">Zone of Truth</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2, Pal 2</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Area</b>: 20-ft.-radius
emanation</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Creatures within the emanation area (or those who enter it)
can&rsquo;t speak any deliberate and intentional lies. Each potentially
affected creature is allowed a save to avoid the effects when the spell
is cast or when the creature first enters the emanation area. Affected
creatures are aware of this enchantment. Therefore, they may avoid
answering questions to which they would normally respond with a lie, or
they may be evasive as long as they remain within the boundaries of the
truth. Creatures who leave the area are free to speak as they choose.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      